import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { AmplifyTheme, Greetings, SignIn, withAuthenticator } from 'aws-amplify-react';
//import MyTheme from './MyTheme';
//import { AmplifyTheme } from 'aws-amplify-react-native';
import { DataStore, Predicates } from "@aws-amplify/datastore";
import { TextEntry } from "./models";

const MyBlue = '#5555ff'
const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { background: MyBlue });
const MySectionFooter = Object.assign({}, AmplifyTheme.sectionFooter, { background: MyBlue });

const MyTheme = Object.assign({}, AmplifyTheme, {
    sectionFooter: MySectionFooter,
    sectionHeader: MySectionHeader
});

/*
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    signInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' }
}
*/
Amplify.configure(aws_exports);

class NewTextEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            description: ''
        };
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleChangeDesc = this.handleChangeDesc.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChangeText(event) {
        this.setState({ text: event.target.value });
    }

    handleChangeDesc(event) {
        this.setState({ description: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        DataStore.save(
            new TextEntry({
                id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                text: this.state.text,
                description: this.state.description,
            })
        ).then(() => {
            this.setState({
                text: '',
                description: '',
            });
            this.props.onSuccess();
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <label>Text:
                    <input type="text" value={this.state.text} onChange={this.handleChangeText} />
                </label>
                <label>Description:
                    <input type="text" value={this.state.description} onChange={this.handleChangeDesc} />
                </label>
                <input type='submit' value='Add' />
            </form>
        );
    }

}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: [],
        };
        this.afterSuccessfulAdd = this.afterSuccessfulAdd.bind(this);
    }

    componentDidMount() {
        DataStore.query(TextEntry).then((entries) => {
            console.log("ENTRIES!", entries);
            this.setState({
                entries: entries,
            });
        })
    }

    afterSuccessfulAdd() {
        this.componentDidMount();
    }

    render() {
        const state = this.state;
        console.log("STATE!", state)
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="App-header-title">Spé.Québec</div>
                </header>
                <div className="AppContent">
                    {state.entries.map((entry, index) => {
                        console.log(entry)
                        return (<div key={entry.id} className='TextEntry'>
                            <label>Id</label><span>{entry.id}</span><br />
                            <label>Text</label><span>{entry.text}</span><br />
                            <label>Description</label><span>{entry.description}</span><br />
                        </div>);
                    })}
                    <NewTextEntry onSuccess={this.afterSuccessfulAdd} />
                </div>
            </div>
        );
        /*
                            <h2>Dévelopment</h2>
                    <a href="https://medium.com/@coryschimmoeller/customizing-the-authentication-experience-of-amplifys-withauthenticator-e6f2089ff469">Customizing the authentication experience of Amplify’s withAuthenticator.</a>
                    <br/>
                    <a href='https://aws-amplify.github.io/docs/js/react'>Amplify React</a>
                    <br/>
                    <iframe title="AWS Part 7: User Sign-Up, Sign-In, and Access Control Using Amplify, Cognito And React"
                            width={560} height={315} frameBorder={0}
                            src='https://www.youtube.com/embed/62kKbCmb7mI'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen={true}>
                    </iframe>
*/
    }
}

export default withAuthenticator(App, true, [<Greetings />, <SignIn />], null, MyTheme);
