// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "speqc-client-20200122014412-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d215l1nce0n9pq.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:e0215bc3-5521-4bc0-9e77-41f181af6978",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DiuORuaEr",
    "aws_user_pools_web_client_id": "35sltimgh4bntc9p87rb66nk0d",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://al5nkgmivfa6tb5xj4bu5rfq7q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
